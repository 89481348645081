import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '0x35b50C7955e7D0E8298e043C9F4dCDef737b9f5a',
  multicall: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  authorizer: '0xA331D84eC860Bf466b4CdCcFb4aC09a1B43F3aE6',
  vault: '0xBA12222222228d8Ba445958a75a0704d566BF2C8',
  weightedPoolFactory: '0x26575A44755E0aaa969FDda1E4291Df22C5624Ea',
  stablePoolFactory: '0x44afeb87c871D8fEA9398a026DeA2BD3A13F5769',
  weth: '0xdFCeA9088c8A88A76FF74892C1457C17dfeef9C1',
  rETH: '0x178E141a0E3b34152f73Ff610437A7bf9B83267A',
  stMATIC: '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4',
  stETH: '0x1643E812aE58766192Cf7D2Cf9567dF2C37e9B7F',
  wstETH: '0x6320cD32aA674d2898A68ec82e869385Fc5f7E2f',
  lidoRelayer: '0xdc10e8f47B6858E32218E01db224795235872B19',
  balancerHelpers: '0x5aDDCCa35b7A0D07C74063c48700C8590E87864E',
  batchRelayer: '0x03F1ab8b19bcE21EB06C364aEc9e40322572a1e9',
  gaugeFactory: '0x224E808FBD9e491Be8988B8A0451FBF777C81B8A',
  balancerMinter: '0xdf0399539A72E2689B8B2DD53C3C2A0883879fDd',
  gaugeController: '0xBB1CE49b16d55A1f2c6e88102f32144C7334B116',
  tokenAdmin: '0x0F32D7D830E20809Bcb9071581A696135dD472B7',
  veBAL: '0x33A99Dcc4C85C014cf12626959111D5898bbCAbF',
  veDelegationProxy: '0xA1F107D1cD709514AE8A914eCB757E95f9cedB31',
  veBALHelpers: '0xE9aac58a051314C085773668f27DD6F673fc0B3F',
  feeDistributor: '0x7F91dcdE02F72b478Dc73cB21730cAcA907c8c44',
  feeDistributorDeprecated: '0x7F91dcdE02F72b478Dc73cB21730cAcA907c8c44',
  faucet: '0xccb0F4Cf5D3F97f4a55bb5f5cA321C3ED033f244',
  gaugeRewardsHelper: '0xC128a9954e6c874eA3d62ce62B468bA073093F25',
};

export default contracts;
