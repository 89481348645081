import { Pools } from '@/types/pools';

const pools: Pools = {
  IdsMap: {
    staBAL:
      '0xee8f7e01e6d992aebb01d146cc43d69e78bcf4a5000000000000000000000005',
    bbAaveUSD: {
      v1: '',
      v2: '0xb72ee0b220e28242c87028b47b288e5319fffd80000000000000000000000004',
    },
    veBAL: '0x4e12ac3528d1f7e74f1615a79c3371a1e6e0fb44000200000000000000000000',
  },
  Pagination: {
    PerPage: 10,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: true,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [
    '0xd88c8e116ca66dd0d271fe9a86c18059072faed500000000000000000000000c', // old staBAL3 pool, causes overflow :(
    '0x05223aae8ca2714e7671f243efbd262d7a91e95b000000000000000000000004', // old balancer boosted aave pool
  ],
  IncludedPoolTypes: [
    'Weighted',
    'Stable',
    'MetaStable',
    'LiquidityBootstrapping',
    'Investment',
    'StablePhantom',
    'ComposableStable',
    'FX',
  ],
  Stable: {
    AllowList: [
      '0xee8f7e01e6d992aebb01d146cc43d69e78bcf4a5000000000000000000000005', // staBAL3
      '0xb72ee0b220e28242c87028b47b288e5319fffd80000000000000000000000004', // bb-a-USD V2
    ],
  },
  Investment: {
    AllowList: [],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [
      '0x4e12ac3528d1f7e74f1615a79c3371a1e6e0fb44000200000000000000000000',
      '0xee8f7e01e6d992aebb01d146cc43d69e78bcf4a5000000000000000000000005', // not sure why the composable stable pool needs to be allowed in the weighted section...
      '0xb72ee0b220e28242c87028b47b288e5319fffd80000000000000000000000004',
      '0xe0d9b20fc6a9f8f5cc52d97f13c098cf8b05667500020000000000000000001a',
      '0x9c67c9037570845d6df136bbe2673b48af69a7c100020000000000000000001c',
      '0xe085dafd81829eba7f75faddb3d2684b5f5c382700020000000000000000001b',
    ],
  },
  Factories: {
    '0x1e7ebb2081574b1526476a9a4118e00b34da58ad': 'weightedPool',
    '0xccce5a335cb90332a3ffc1fe026b840203d177bf': 'stablePool',
    '0xb7cc3aee7629d8d70b2b1f9b5167920872a4c112': 'liquidityBootstrappingPool',
    '0xf3af68970c932232edade5249fcff04866a14780': 'managedPool',
    '0xeffafc1365b9f3a7034f1e0623d85be2248c0f6b': 'composableStablePool',
  },
  Stakable: {
    AllowList: [
      '0x4e12ac3528d1f7e74f1615a79c3371a1e6e0fb44000200000000000000000000',
    ],
  },
  Metadata: {
    '0xb72ee0b220e28242c87028b47b288e5319fffd80000000000000000000000004': {
      name: 'Balancer Boosted Aave USD',
      hasIcon: true,
    },
  },
  Deep: [
    '0xb72ee0b220e28242c87028b47b288e5319fffd80000000000000000000000004', // bb-a-USD2
  ],
  BoostedApr: [
    '0xb72ee0b220e28242c87028b47b288e5319fffd80000000000000000000000004', // bb-a-USD2
  ],
  DisabledJoins: [],
  Deprecated: {},
  BrandedRedirect: {},
};

export default pools;
