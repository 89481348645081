<template>
  <footer v-once>
    <div
      class="xl:container lg:px-4 xl:mx-auto dark:border-t dark:border-gray-800"
    >
      <div class="flex flex-col md:flex-row lg:gap-48 py-6 px-4 lg:px-0">
        <div class="flex flex-col gap-4 justify-items-start w-1/3">
          <router-link
            class="font-medium link"
            :to="{ name: 'home', params: { networkSlug } }"
          >
            <img src="/images/glue_main.png" width="130" />
          </router-link>
        </div>
        <div class="flex flex-col gap-8 w-3/4">
          <div
            class="flex flex-wrap md:order-2 gap-x-3 lg:gap-x-14 gap-y-2 justify-end text-right columns-5"
          >
            <div class="flex flex-wrap gap-y-2 md:justify-end w-32">
              <p class="w-full">
                <router-link
                  class="text-sm text-black"
                  :to="{ name: 'home', params: { networkSlug } }"
                >
                  {{ $t('pool') }}
                </router-link>
              </p>
            </div>
            <div class="flex flex-wrap gap-y-2 md:justify-end w-32">
              <p class="w-full">
                <router-link
                  class="text-sm text-black"
                  :to="{ name: 'swap', params: { networkSlug } }"
                >
                  {{ $t('swap') }}
                </router-link>
              </p>
              <!--
              <p class="w-full">
                <a
                  class="text-sm text-black"
                  @click="isThirdPartyServicesModalVisible = true"
                  >{{ $t('policies.thirdPartyServices') }}</a
                >
              </p>
        -->
            </div>
            <!--    <div class="flex flex-wrap gap-y-2 md:justify-end w-32">
              <p class="w-full">
                <router-link
                  class="text-sm text-black"
                  :to="{ name: 'claim', params: { networkSlug } }"
                >
                  {{ $t('claim') }}
                </router-link>
              </p>
              <p class="w-full">
                <router-link
                  class="text-sm text-black"
                  :to="{ name: 'cookies-policy' }"
                >
                  {{ $t('policies.cookiesPolicy') }}
                </router-link>
              </p>
            </div>
            -->
            <div class="flex flex-wrap gap-y-2 md:justify-end w-32">
              <p class="w-full">
                <router-link
                  class="text-sm text-black"
                  :to="{ name: 'portfolio', params: { networkSlug } }"
                >
                  {{ $t('portfolio') }}
                </router-link>
              </p>
              <!--
              <p class="w-full">
                <router-link
                  class="text-sm text-black"
                  :to="{ name: 'privacy-policy' }"
                >
                  {{ $t('policies.privacyPolicy') }}
                </router-link>
              </p>
         -->
            </div>
            <!--
           <div class="flex flex-wrap gap-y-2 md:justify-end w-32">
              <p class="w-full">
                <router-link
                  class="text-sm text-black"
                  :to="{ name: 'vebal', params: { networkSlug } }"
                >
                  {{ $t('vebal') }}
                </router-link>
              </p>
              <p class="w-full">
                <router-link
                  class="text-sm text-black"
                  :to="{ name: 'terms-of-use' }"
                >
                  {{ $t('policies.termsOfUse') }}
                </router-link>
              </p>
            </div>
       -->
          </div>
          <!--
          <div
            class="flex flex-wrap md:order-3 gap-x-3 lg:gap-x-4 gap-y-2 md:justify-end"
          >
            <p>
              <router-link class="policy" :to="{ name: 'terms-of-use' }">
                {{ $t('policies.termsOfUse') }}
              </router-link>
            </p>
            <p>
              <router-link class="policy" :to="{ name: 'privacy-policy' }">
                {{ $t('policies.privacyPolicy') }}
              </router-link>
            </p>
            <p>
              <router-link class="policy" :to="{ name: 'cookies-policy' }">
                {{ $t('policies.cookiesPolicy') }}
              </router-link>
            </p>
            <p>
              <router-link class="policy" :to="{ name: 'risks' }">
                {{ $t('policies.risks') }}
              </router-link>
            </p>
            <p>
              <span
                class="cursor-pointer policy"
                @click="isThirdPartyServicesModalVisible = true"
              >
                {{ $t('policies.thirdPartyServices') }}
              </span>
            </p>
          </div>
-->
        </div>
      </div>
      <div
        class="flex flex-col md:flex-row gap-8 md:justify-between py-4 px-4 lg:px-0 text-gray-400 border-t border-gray-300"
      >
        2024 Glue. All rights reserved
      </div>
    </div>
  </footer>
</template>

<script>
import { useI18n } from 'vue-i18n';

import { isThirdPartyServicesModalVisible } from '@/App.vue';
import { EXTERNAL_LINKS } from '@/constants/links';
import useBreakpoints from '@/composables/useBreakpoints';
import useNetwork from '@/composables/useNetwork';

export default {
  setup() {
    const { t } = useI18n();
    const { networkSlug } = useNetwork();
    const { upToMediumBreakpoint } = useBreakpoints();
    function handleEmailChange(event) {
      // TODO: add email validation
      console.log(event.target.value);
    }
    return {
      EXTERNAL_LINKS,
      t,
      networkSlug,
      isThirdPartyServicesModalVisible,
      upToMediumBreakpoint,
      handleEmailChange,
    };
  },
};
</script>

<style scoped>
footer {
  @apply bg-gray-50 dark:bg-gray-900;
}

.w-full {
  @apply font-bold;
}

form {
  @apply flex;
}

input {
  @apply flex-grow-0;
}

footer :deep(.logotype) {
  @apply origin-top-left;

  transform: scale(1.5);
}

.link {
  @apply text-black dark:text-white transition-colors items-center no-underline hover:text-purple-600;
}

.link--external {
  @apply text-sm;
}

.link:hover,
.link:focus-visible {
  @apply text-purple-600 dark:text-yellow-500 no-underline;
}

.link:focus:not(:focus-visible) {
  outline: none;
}

.policy {
  @apply text-sm text-gray-600 dark:text-gray-400 hover:text-purple-600 dark:hover:text-yellow-500;
}

.arrow {
  @apply text-gray-300 dark:text-gray-600 group-hover:text-purple-600 dark:group-hover:text-yellow-500;
}

.router-link-active {
  @apply text-green-600 dark:text-blue-400;
}
</style>
