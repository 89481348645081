import { Config } from '../types';
import contracts from './contracts';
import keys from './keys';
import pools from './pools';
import tokenlists from './tokenlists';
import tokens from './tokens';
import rateProviders from './rateProviders';

const config: Config = {
  key: '1300',
  chainId: 1300,
  chainName: 'Glue',
  name: 'Glue',
  shortName: 'Glue',
  slug: 'glue',
  network: 'glue',
  unknown: false,
  visibleInUI: true,
  testNetwork: false,
  rpc: 'https://rpc.collator-1.atera.papers.tech',
  ws: 'wss://wss.collator-1.atera.papers.tech',
  explorer: 'https://atera-explorer.dev.gke.papers.tech',
  explorerName: 'BlockScout',
  subgraph:
    'https://http.thegraph.dev.gke.papers.tech/subgraphs/name/balancer-labs/balancer-v2',
  balancerApi: '',
  poolsUrlV2: '',
  subgraphs: {
    main: [
      'https://http.thegraph.dev.gke.papers.tech/subgraphs/name/balancer-labs/balancer-v2',
    ],
    aave: 'https://http.thegraph.dev.gke.papers.tech/subgraphs/name/aave/protocol-v3',
    gauge:
      'https://http.thegraph.dev.gke.papers.tech/subgraphs/name/balancer-labs/gauges',
    blocks:
      'https://http.thegraph.dev.gke.papers.tech/subgraphs/name/blocklytics/atera-blocks',
  },
  bridgeUrl: '',
  supportsEIP1559: true,
  supportsElementPools: true,
  blockTime: 12,
  nativeAsset: {
    name: 'Glue',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    symbol: 'ATE',
    decimals: 18,
    deeplinkId: 'glue',
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png',
    minTransactionBuffer: '0.1',
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'ethereum',
      platformId: 'ethereum',
    },
    apyVision: {
      networkName: 'eth',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 2,
    weight: 100,
  },
  tokenlists,
  rateProviders,
};

export default config;
