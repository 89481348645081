import { Keys } from '../types';

const keys: Keys = {
  infura: '',
  alchemy: 'ODJ9G5Ipv-Hb2zTWMNbUFIqv9WtqBOc2',
  graph: '',
  balancerApi: '',
};

export default keys;
