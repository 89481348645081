<script lang="ts" setup>
import { computed } from 'vue';
import { RouterLinkProps } from 'vue-router';

interface Props extends RouterLinkProps {
  active: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  active: false,
});

const classes = computed(() => ({
  'border-white dark:border-gray-900': !props.active,
  'border-blue-600 dark:border-blue-400 text-blue-700 dark:text-blue-400':
    props.active,
}));
</script>

<template>
  <router-link v-bind="props" :class="['desktop-link-item', classes]">
    <slot />
  </router-link>
</template>

<style scoped>
.desktop-link-item {
  @apply text-black h-full flex-col justify-center cursor-pointer hover:text-green-400 dark:hover:text-yellow-500 flex relative
    overflow-hidden p-0 transition-all
    ease-in-out duration-500;
}

.desktop-link-item.router-link-active {
  @apply text-green-400 hover:text-green-600 transition-colors;
}
</style>
