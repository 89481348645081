import { TokenConstants } from '../types';

const tokens: TokenConstants = {
  ClaimableTokens: ['0x1c7e3385ed1eef2264a406b88911edd1076deff7'],
  Popular: {
    Symbols: ['WATE', 'BAL'],
  },
  InitialSwapTokens: {
    input: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    output: '0x6B175474E89094C44Da98b954EedeAC495271d0F', // TODO: i have this as mainnet DAI address...is that correct?
  },
  Addresses: {
    nativeAsset: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    wNativeAsset: '0x0000000000000000000000000000000000000802',
    WETH: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', // TODO: is this used?
    BAL: '0x1c7e3385ed1eef2264a406b88911edd1076deff7',
    bbaUSD: '',
    bbaUSDv2: '0xb72ee0b220e28242c87028b47b288e5319fffd80',
  },
  PriceChainMap: {
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee':
      '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    '0x0000000000000000000000000000000000000802':
      '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    '0x1c7e3385ed1eef2264a406b88911edd1076deff7':
      '0xba100000625a3754423978a60c9317c58a424e3d',
    '0xa1302aa2921b81ea33f45c2149f33a1174432ddd':
      '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
    '0x73b14e2a47615dc3d63c754225f3ad95d5ac8998':
      '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    '0x3d9687c616ea5b0a85d820f562fc30461249cdb7':
      '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    '0x523bbe2e4d0889986d2a4e3b93be27803014457f':
      '0xc00e94cb662c3520282e6f5717214004a7f26888',
    '0x1115904c81d86efae298025b9bb039c1981216c6':
      '0xd33526068d116ce69f19a9ee46f0bd304f21a51f',
    '0x5f48d795a4375e087a86037ee478720d904ced7b':
      '0x514910771af9ca656af840dff83e8264ecf986ca',
    '0xac4d538904efaeb286dd69e0fcc726034467560f':
      '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    '0x964ca5e4d5d6b096b4f5e6bf5fcb804094f70a4e':
      '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    '0xef582d363b1de0c8dfc25a339f17a4848c5648ba':
      '0xd533a949740bb3306d119cc777fa900ba034cd52',
    '0x3a1e2a1f9f25a416bdc3439990d114d661dabc1c':
      '0xb8c77482e45f1f44de1745f52c74426c631bdd52',
    '0xee3f3973244c25c0f6ceb475671788c90235de7e':
      '0x75231f58b43240c9718dd58b4967c5114342a86c',
    '0x44eeb3c786db9641340e7a8db79f64226f8cff90':
      '0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5',
    '0x1777ac3f58b10c6dd5b9268b54ee4ebce58448a6':
      '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72',
    '0xeb2995da01885cc99b3c15eb994ca90d841e2a18':
      '0x667102bd3413bfeaa3dffb48fa8288819e480a88',
    '0x4ce77a1fc289fb34384b925ec6f59cf323a81640':
      '0xa117000000f279d81a1d3cc75430faa017fa5a2e',
    '0xf3eb2b2da904e8c40ed2f404df96c127eaf8abde':
      '0x92d6c1e31e14520e676a687f0a93788b716beff5',
    '0x96e284d3803d02b8d370b06652632b310f5cc8fb':
      '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    '0x201be32d10fdc5fc08923c0be95b31c811734c1b':
      '0x4d224452801aced8b2f0aebe155379bb5d594381',
    '0x678537761a3535055c5cbb7752c29bbd0451487e':
      '0x152649ea73beab28c5b49b26eb48f7ead6d4c898',
    '0x3ed265b2bca85075071cab3e88283fde1cc4ee27':
      '0xaedf386b755465871ff874e3e37af5976e247064',
    '0x076a0cc4bcd238e5ce4dfa6e05ada53a5dd21e12':
      '0xb50721bcf8d664c30412cfbc6cf7a15145234ad1',
    '0x2b666f100b0a8bcaa478eb4c419f5530840e2ecd':
      '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    '0x7ab17eea17f0e612d86fc1f4d0bb80037b61f4ac':
      '0x111111111117dc0aa78b770fa6a738034120c302',
    '0x841dd494efe876eb1ce74e366320a87ca8bca79d':
      '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
    '0xc9176c90e7ae1b635b687bb80d771bf6f3144830':
      '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
    '0xffffffff73c778920cb69d2fa0c6049fe98570bb':
      '0xdac17f958d2ee523a2206206994597c13d831ec7',
    '0xffffffff659307592a3174e3cc4315943fffb14b':
      '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    '0xffffffffe094d8fec365f321aaa738c73f246119':
      '0x6b175474e89094c44da98b954eedeac495271d0f',
  },
};

export default tokens;
