import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: '',
  multicall: '0x1b47518c3bb68ceb3dfa785fe206c050b4cb51d7',
  authorizer: '0xdec0294c792e6c6fc086226f8ab2f36e8ac1813a',
  vault: '0xe3aa5a4d8cc94d7dd25fb5fe48e9d5da980f0995',
  weightedPoolFactory: '0x1e7ebb2081574b1526476a9a4118e00b34da58ad',
  stablePoolFactory: '0xccce5a335cb90332a3ffc1fe026b840203d177bf',
  weth: '0x0000000000000000000000000000000000000802',
  rETH: '',
  stMATIC: '',
  stETH: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
  wstETH: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
  lidoRelayer: '',
  balancerHelpers: '0x462cba8f53839330bc270ea48c16fd4d3d09d0d5',
  batchRelayer: '0x61b183c727f2e5c7b6afd938b1d63e2630fbbaba',
  veBAL: '0xe1bdf8c3777509677906d8185de5d4ac73dedff2',
  gaugeController: '0xf30f7079f068d9c2de69cc81a50b432d74cc4e13',
  gaugeFactory: '0xb63ab8cfa15a15bd626fb0e38882f43d9cfff4d1',
  balancerMinter: '0x654da01d0b8dbd66b9f325e33456efc1592b3ef8',
  tokenAdmin: '0x4489216e4754c584c3303950b858a565779ed38e',
  veDelegationProxy: '0x92d7e8da2559c000f4f772a738de6ae434b3a657',
  veBALHelpers: '0xf30f7079f068d9c2de69cc81a50b432d74cc4e13',
  feeDistributor: '0x4d78998429cf466463eca06f2502617ae1357a6f',
  feeDistributorDeprecated: '0x4d78998429cf466463eca06f2502617ae1357a6f',
  faucet: '',
};

export default contracts;
