<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue';

import useAlerts from '@/composables/useAlerts';
import useBreakpoints from '@/composables/useBreakpoints';
import useFathom from '@/composables/useFathom';
import useNetwork from '@/composables/useNetwork';

import AppNavActions from './AppNavActions.vue';
import AppNavAlert from './AppNavAlert.vue';
import DesktopLinks from './DesktopLinks/DesktopLinks.vue';

/**
 * STATE
 */
const appNav = ref<HTMLDivElement>();

/**
 * COMPOSABLES
 */
const { isDesktop } = useBreakpoints();
const { trackGoal, Goals } = useFathom();
const { currentAlert } = useAlerts();
const { networkSlug } = useNetwork();

/**
 * METHODS
 */
function handleScroll() {
  if (!appNav.value) return;

  if (window.scrollY === 0) {
    appNav.value.classList.remove('shadow-3xl');
  } else {
    appNav.value.classList.add('shadow-3xl');
  }
}

/**
 * LIFECYCLE
 */
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <AppNavAlert v-if="currentAlert" :alert="currentAlert" />
  <nav id="app-nav" ref="appNav" class="sticky top-0 shadow-sm">
    <div class="xl:container xl:px-4 pt-4 md:pt-8 pr-2 pb-4 pl-2 xl:mx-auto">
      <div class="flex justify-between items-center h-full">
        <div class="flex items-center h-full">
          <router-link
            :to="{ name: 'home', params: { networkSlug } }"
            @click="trackGoal(Goals.ClickNavLogo)"
          >
            <img src="/images/glue_main.png" alt="glue" width="130" />
          </router-link>
        </div>
        <div>
          <DesktopLinks v-if="isDesktop" class="font-medium" />
        </div>
        <AppNavActions />
      </div>
    </div>
  </nav>
</template>

<style scoped>
#app-nav {
  @apply w-full z-30;
  @apply bg-white dark:bg-gray-900;
  @apply border-b border-transparent;

  transition: all 0.2s ease-in-out;
}
</style>
